import MenuController from "./menuController";
import {
  gsap,
  Power2
} from "gsap";
import barba from "@barba/core";
import barbaCss from "@barba/css";
import {
  Swiper
} from "swiper/bundle";
import "./nextparticle.min.js";
import Parallax from "parallax-js";

function parallax() {
  var scene = document.getElementById('scene');
  var parallaxInstance = new Parallax(scene);
}

function logoAnimation() {
  var nextParticle = new NextParticle(document.all.logo);
}

function playVideo() {
  var vids = document.getElementsByTagName("video");
  // vids is an HTMLCollection
  for (var i = 0; i < vids.length; i++) {
    console.log(vids.item(i).play());
  }
}

const loadingTween = gsap.timeline({
  paused: true,
});

const intro = gsap.timeline({
  paused: true,
});

const showcase = gsap.timeline({
  paused: true,
});
showcase.from(".menu__item-inner", 3, {
  y: 100,
  ease: "Power4.easeOut",
  stagger: {
    amount: 0.5,
  },
});

loadingTween
  .to("#percent", 0.5, {
    opacity: 0,
    yPercent: -50,
  })
  .to(".overlay", {
    duration: 0.25,
    yPercent: -100,
    transformOrigin: "top top",
  });
intro
  .from(".element--left", 0.15, {
    opacity: 0,
    y: 100,
    ease: "Power4.easeOut",
  })
  .from(
    ".element--right",
    0.25, {
      opacity: 0,
      y: 20,
      ease: "Power4.easeOut",
    },
    0.05
  )
  .from(".element--dots", 0.35, {
    opacity: 0,
    duration: 0.25,
    y: 100,
    ease: "Power4.easeOut",
  })
  .from(".nav-link", 0.2, {
    y: 200,
    ease: "Power4.easeOut",
    duration: 0.3,
    stagger: {
      amount: 0.3,
    },
  })
  .from(
    ".main",
    0.25, {
      y: 200,
      opacity: 0,
      ease: "Power4.easeOut",
    },
    "-=.65"
  )
  .from(
    "#planes",
    0.25, {
      y: 200,
      opacity: 0,
      ease: "Power4.easeOut",
    },
    "-=.55"
  );

function SwiperAboutSlider() {
  let swiperAbout = new Swiper(".about__swiper", {
    fadeEffect: {
      crossFade: true
    },
    effect: "fade",
    speed: 750,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    allowTouchMove: true,
    breakpoints: {
      1200: {
        allowTouchMove: false
      },
    }
  });
  const totalAbout = document.querySelector(".total-about");
  totalAbout.innerHTML = swiperAbout.slides.length;
  progressBarChangeAbout();

  function progressBarChangeAbout() {
    const progressBarAbout = gsap.timeline();
    let curnumAbout = document.querySelector(".current");
    swiperAbout.on("slideChangeTransitionStart", function () {
      let indAbout = swiperAbout.realIndex + 1;
      progressBarAbout.to(curnumAbout, 0.2, {
        force3D: true,
        y: -10,
        opacity: 0,
        ease: Power2.easeOut,
        onComplete: function () {
          progressBarAbout.to(curnumAbout, 0.1, {
            force3D: true,
            y: 0,
            ease: Power2.easeOut,
          });
          curnumAbout.innerHTML = `${indAbout}`;
        },
      });
      progressBarAbout.to(curnumAbout, 0.2, {
        force3D: true,
        y: 0,
        opacity: 1,
        ease: Power2.easeOut,
      });
    });
  }
}

function SwiperShowcaseSlider() {
  let swiperShowcase = new Swiper(".showcase", {
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: ".swiper-button-next.showcase-next",
      prevEl: ".swiper-button-prev.showcase-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    loop: false,
    speed: 1000,
    parallax: true,
    allowTouchMove: true,
    breakpoints: {
      992: {
        allowTouchMove: false
      },
    }
  });
  const totalShowcaseSlider = document.querySelector(".total-showcase-slider");
  totalShowcaseSlider.innerHTML = swiperShowcase.slides.length;
  progressBarChangeAboutSlider();

  function progressBarChangeAboutSlider() {
    const progressBarShowcaseSlider = gsap.timeline();
    let curnumShowcaseSlider = document.querySelector(".current");
    swiperShowcase.on("slideChangeTransitionStart", function () {
      new MenuController(document.querySelector(".menu-2"));
      let indShowcaseSlider = swiperShowcase.realIndex + 1;
      progressBarShowcaseSlider.to(curnumShowcaseSlider, 0.2, {
        force3D: true,
        y: -10,
        opacity: 0,
        ease: Power2.easeOut,
        onComplete: function () {
          progressBarShowcaseSlider.to(curnumShowcaseSlider, 0.1, {
            force3D: true,
            y: 0,
            ease: Power2.easeOut,
          });
          curnumShowcaseSlider.innerHTML = `${indShowcaseSlider}`;
        },
      });
      progressBarShowcaseSlider.to(curnumShowcaseSlider, 0.2, {
        force3D: true,
        y: 0,
        opacity: 1,
        ease: Power2.easeOut,
      });
    });
  }
}

function restartVideo() {
  var vids = document.getElementById("video");
  vids.pause();
  vids.currentTime = 0;
  vids.play();
}

function SwiperCaseStudySlider() {
  let swiperCaseStudy = new Swiper(".case-study-slider", {
    fadeEffect: {
      crossFade: true
    },
    effect: "fade",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    keyboard: {
      enabled: true,
    },
    speed: 1400,
    allowTouchMove: true,
    breakpoints: {
      992: {
        allowTouchMove: false
      },
    }
  });
  const total = document.querySelector(".total");
  total.innerHTML = swiperCaseStudy.slides.length;
  progressBarChange();

  function progressBarChange() {
    const progressBar = gsap.timeline();
    let curnum = document.querySelector(".current");
    swiperCaseStudy.on("slideChangeTransitionStart", function () {
      let ind = swiperCaseStudy.realIndex + 1;
      progressBar.to(curnum, 0.2, {
        force3D: true,
        y: -10,
        opacity: 0,
        ease: Power2.easeOut,
        onComplete: function () {
          progressBar.to(curnum, 0.1, {
            force3D: true,
            y: 0,
            ease: Power2.easeOut,
          });
          curnum.innerHTML = `${ind}`;
        },
      });
      progressBar.to(curnum, 0.2, {
        force3D: true,
        y: 0,
        opacity: 1,
        ease: Power2.easeOut,
      });
    });
  }
  swiperCaseStudy.on("slideChangeTransitionEnd", function () {
    restartVideo();
  })
}

function getCurrentYear() {
  var d = new Date();
  var n = d.getFullYear();
  document.getElementById("year").innerHTML = "&#169; " + n;
}

barba.use(barbaCss);

barba.init({
  preventRunning: true,
  transitions: [{
    preventRunning: true,
    name: "slide",
    leave() {
      console.clear();
    },
    afterEnter() {
      console.clear();
    },
  }, ],
  views: [{
      namespace: "case-study",
      beforeEnter(data) {
        getCurrentYear();
      },
      afterEnter(data) {
        SwiperCaseStudySlider();
      },
    },
    {
      namespace: "about",
      beforeEnter(data) {
        getCurrentYear();
      },
      afterEnter(data) {
        new MenuController(document.querySelector(".menu"));
        SwiperAboutSlider();
      },
    },
    {
      namespace: "showcase",
      beforeEnter(data) {
        getCurrentYear();
      },
      afterEnter(data) {
        new MenuController(document.querySelector(".menu"));
        SwiperShowcaseSlider();
        const showcase = gsap.timeline();
        showcase.from(".menu__item-inner", {
          y: 200,
          ease: "Power4.easeOut",
          duration: 1,
          delay: -0.25,
          stagger: {
            amount: 0.5,
          },
        });
      },
    },
    {
      namespace: "journal",
      beforeEnter(data) {
        getCurrentYear();
      }
    },
    {
      namespace: "contact",
      beforeEnter(data) {
        getCurrentYear();
      }
    },
    {
      namespace: "home",
      afterEnter(data) {
        parallax();
        if (window.screen.width >= 1199) {
          logoAnimation();
        }
      },
    },
  ],
});

barba.hooks.before((data) => {
  document.querySelector("body").classList.add("pointer-events");
});
barba.hooks.beforeLeave((data) => {
  intro.to(
    ".main",
    1.25, {
      y: 200,
      duration: 1,
      opacity: 0,
      ease: "Power4.easeOut",
    },
    1
  );
  console.clear();
});
barba.hooks.beforeEnter((data) => {
  intro.from(".main", 3.5, {
    y: 200,
    duration: 3.5,
    opacity: 0,
    ease: "Power4.easeOut",
  });
});

barba.hooks.enter((data) => {
  playVideo();
});

barba.hooks.afterEnter((data) => {
  document.querySelector("body").classList.remove("pointer-events");
  console.clear();
});


window.onload = function () {
  var id;
  var width = 1;

  function loading() {
    id = setInterval(frame, 50);
  }

  function frame() {
    if (width >= 100) {
      clearInterval(id);
      loadingTween.play();
    } else {
      width++;
      document.getElementById("percent").innerHTML = width + "%";
    }
  }
  loading();
  frame();
  loadingTween.eventCallback("onComplete", function () {
    intro.play();
    showcase.play();
  });
}