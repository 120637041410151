import { gsap } from 'gsap';
import MenuItem from './menuItem';

export default class MenuController {
    constructor(menuEl) {
        this.DOM = {menu: menuEl};
        // Menu item elements
        this.DOM.menuItems = [...this.DOM.menu.querySelectorAll('.menu__item')];
        // Menu item properties that will animate as we move the mouse around the menu
        // we will be using interpolation to achieve smooth animations. 
        // the “previous” and “current” values are the values to interpolate. 
        // the value applied to the element, this case the image element (this.DOM.reveal) will be a value between these two values at a specific increment. 
        // the amt is the amount to interpolate.
        this.animatableProperties = {
            // translationX
            tx: {previous: 0, current: 0, amt: 0.08},
            // translationY
            ty: {previous: 0, current: 0, amt: 0.08},
            // Rotation angle
            rotation: {previous: 0, current: 0, amt: 0.05}
        };
        
        // Array for the MenuItem instances
        this.menuItems = [];
        this.DOM.menuItems.forEach(menuItemEl => this.menuItems.push(new MenuItem(menuItemEl, this.animatableProperties)));
        
        // Array for the ContentItem instances
    }
    // gets the menu item (and its texts and number elements) and content item given a specific position/index
    getCurrentData(position) {
        return {
            menuItem: this.menuItems[position],
            // all menu item's texts and numbers
            texts: this.menuItems.map(t => t.DOM.inner),
            numbers: this.menuItems.map(t => t.DOM.number),
            // ...and its contentItem  
        };
    }
}